import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { 
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  rainbowWallet
} from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThirdwebProvider
    activeChain="ethereum"
    clientId="234de47c39d9056361e1cb773cb42899b323cea8297ec984eb3232a4d8e5d414a5ea60db3c57aade8c06032165398fe8c9c1c567da7083c10eb2eb4cdeaecc87"
    sdkOptions={{
      gatewayUrls: ["https://atari.myfilebase.com/ipfs/"],
    }}
    supportedWallets={[
      metamaskWallet(),
      coinbaseWallet(),
      walletConnect(),
      safeWallet({
        personalWallets: [
          metamaskWallet(),
          coinbaseWallet(),
          walletConnect(),
        ],
      }),
      trustWallet(),
      rainbowWallet()
    ]}
  >
    <App />
  </ThirdwebProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
